<template>
  <div class="vz-file-uploader-new">
    <input
      ref="fileUploader"
      type="file"
      :multiple="multiple"
      class="vz-file-uploader-new-input"
      :directory="directory"
      :accept="accepts"
      @change="onChange"
    >
    <div :class="{'by-column': byColumn}">
      <div>
        <div class="vz-file-uploader-new-control-wrapper">
          <label
            v-if="label"
            class="vz-file-uploader-new-label"
            :class="labelClass"
            v-text="label"
          />
          <vz-button
            :outline="outline"
            :variant="variant"
            :icon-name="iconName"
            :icon-size="iconSize"
            :big="big"
            :large="large"
            :disabled="disabled"
            @click="onSelectFile"
          >
            {{ btnTitle }}
          </vz-button>
        </div>
        <slot name="content"/>
      </div>
      <div v-if="fileList" class="file-list">
        <template v-for="(file, index) of modelValue">
          <div
            v-if="index < maxFiles"
            :key="`${file.name}-${index}`"
            class="file-list--item"
          >
            <vz-icon
              size=18
              no-masked-icon
              class="mr-5"
              variant="primary"
              url="/svg/vz-documents.svg"
            />
            <span class="item--file" :title="file.name">{{ file.name }}</span>
            <vz-close-btn
              class="close-btn"
              :size="14"
              :width="20"
              :height="20"
              title="Удалить"
              @click="onDelete(file)"
            />
          </div>
        </template>
      </div>
      <div v-if="showUploadProblems">
        <slot
          name="error"
          :accept="uploadProblems.accept"
          :maxFiles="uploadProblems.maxFiles"
          :size="uploadProblems.size"
          :files="tempFiles"
        >
          <div class="color-primary fs-14" v-if="uploadProblemsList.length">
            <div v-for="(item, index) in uploadProblemsList" :key="index">
              <vz-icon v-if="uploadProblemsWithIcon" name="information-outline" variant="primary" size=16 class="mt-5"/>
              {{ item }}
            </div>
          </div>
        </slot>
      </div>
      <vz-invalid-message v-if="isShowError">
        {{ errorText }}
      </vz-invalid-message>
    </div>
  </div>
</template>

<script setup lang="ts">
import {KeyValueObject, ValidationRule} from '@/types/types'
import {useEmits, useUploaderProps, useRefs} from '@/common/uploader'
import {useIconProps} from '~/common/ui-kit-icon'
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzCloseBtn from '~/components/ui-kit/vz-close-btn.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';
import {required} from '@/common/validators'

const { isShowError, errorText, doValidate, dispatchFormEvent } = useValidateble()

defineExpose({
  checkError,
  isShowError
})

const emit = defineEmits([...useEmits()])
const props = defineProps({
  ...useUploaderProps,
  ...useIconProps,
  label: {
    type: String,
    default: ''
  },
  btnTitle: {
    type: String,
    default: 'Добавить'
  },
  big: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  },
  variant: {
    type: String,
    default: 'info'
  },
  outline: {
    type: Boolean,
    default: false
  },
  fileList: {
    type: Boolean,
    default: false
  },
  directory: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  uploadProblemsWithIcon: {
    type: Boolean,
    default: false
  },
  byColumn: {
    type: Boolean,
    default: false
  }
})

const {
  fileUploader,
  tempFiles,
  isMounted,
  uploadProblems
} = useRefs()

const labelClass = computed((): KeyValueObject => {
  return {
    required: props.required
  }
})

const sizeString = computed((): string => {
  const mbSize = props.size / 1048576
  return mbSize.toFixed(mbSize > 1 ? 0 : 1)
})

const accepts = computed((): string => {
  return acceptList.value.map(a => a.includes('*') ? a : ('.' + a)).join(',')
})

const acceptList = computed((): string[] => {
  if (!Array.isArray(props.accept)) {
    return []
  }
  return props.accept.map(a => a.toLowerCase())
})

const uploadProblemsList = computed(() => {
  const data = []
  if (uploadProblems.value.accept) {
    data.push('Неверный формат файла')
  }
  if (uploadProblems.value.maxFiles) {
    data.push(`Превышено максимальное количество файлов (${props.maxFiles})`)
  }
  if (uploadProblems.value.size) {
    data.push(`Превышен максимальный размер файла (${sizeString.value} MB)`)
  }
  return data
})

onMounted((): void => {
  isMounted.value = true
})

async function checkError(silent: boolean = false) {
  return props.required
    ? await doValidate(props.modelValue?.[0], [{ cb: required, customMessage: 'Необходимо загрузить файл' }], silent)
    : false
}

const onSelectFile = (): void => {
  if (isMounted.value) {
    fileUploader.value.click()
  }
}

const onChange = async (): Promise<void> => {
  clearUploadProblems()
  const { files }: { files: FileList | null } = fileUploader.value
  const oldFiles = props.appendFiles ? [...props.modelValue] : []
  if (files) {
    tempFiles.value = Array.from(files)
    tempFiles.value = tempFiles.value.filter((file: File) => checkAccept(file.name))
    tempFiles.value = tempFiles.value.filter((file: File) => checkSize(file))

    if (props.maxFiles && (tempFiles.value.length + oldFiles.length) > props.maxFiles) {
      const count = props.maxFiles - oldFiles.length
      tempFiles.value = tempFiles.value.slice(0, count)
      uploadProblems.value.maxFiles = true
    }
    clearFileUploaderInput()
  }

  const f = [...oldFiles, ...tempFiles.value]
  emit('update:modelValue', f)
  emit('change', f, uploadProblems.value)
  nextTick(() => {
    dispatchFormEvent(fileUploader.value)
  })
}

function onDelete(file: File): void {
  clearUploadProblems()
  const files = props.modelValue?.filter(f => f !== file)
  emit('update:modelValue', files)
  nextTick(() => {
    checkError()
  })
}

const checkAccept = (fileExtensions: string): boolean => {
  if (Array.isArray(props.accept) && !props.accept.find(a => a.includes('*'))) {
    const ext = fileExtensions.split('.').pop()?.toLowerCase() || ''
    if (ext && !acceptList.value.includes(ext)) {
      uploadProblems.value.accept = true
    }
    return acceptList.value.includes(ext)
  }
  return true
}

const checkSize = (file: File): boolean => {
  if (props.size && file.size > props.size) {
    uploadProblems.value.size = true
    return false
  }
  return true
}

const clearUploadProblems = (): void => {
  uploadProblems.value = {
    accept: false,
    size: false,
    maxFiles: false
  }
}

const clearFileUploaderInput = (): void => {
  fileUploader.value.files = null
  fileUploader.value.value = null
}
</script>
